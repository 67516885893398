const space = [
    0,
    '6px', // xs
    '12px',
    '18px', // sm
    '24px',
    '30px', // md
    '36px',
    '42px',
    '48px', // lg
    '54px',
    '60px', // xl
    '66px',
    '72px', // xxl
    '78px',
    '84px', // xxxl
    '90px',
]

space.xxs = space[1]
space.xs = space[2]
space.sm = space[3]
space.md = space[5]
space.lg = space[8]
space.xl = space[10]
space.xxl = space[12]
space.xxxl = space[14]
space.xxxxl = space[15]

space["-xxs"] = "-" + space[1]
space["-xs"] = "-" + space[2]
space["-sm"] = "-" + space[3]
space["-md"] = "-" + space[5]
space["-lg"] = "-" + space[8]
space["-xl"] = "-" + space[10]
space["-xxl"] = "-" + space[12]
space["-xxxl"] = "-" + space[14]
space["-xxxxl"] = "-" + space[15]

export default space
