import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import Theme from './src/theme/Theme'
import { GlobalStyle } from './src/theme/GlobalStyle'
import { myFirebase } from './src/system/firebase/index';
import { firebaseFunctionCall } from './src/system/firebase/useFunctions'
import { store } from './src/system/redux/store'

import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

// eslint-disable-next-line react/display-name
export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts

    // Initialise Firestore connection
    const initFirebase = myFirebase
    if ( initFirebase ) {
        // Firestore has been Initialised and connection successful
    }

    const verifyCallback = async (response) => {
        const res = await firebaseFunctionCall('verifyRecaptcha')({ token: encodeURIComponent(response) })
        window.verifyRecaptcha = res
    }

    return <Provider store={store}>
        <ThemeProvider theme={Theme}>
            <GlobalStyle />
            <GoogleReCaptchaProvider reCaptchaKey="6LfJRyUbAAAAAO_MNQj55iHND07YIZKEDt9IXb6t">
                {element}
                <GoogleReCaptcha onVerify={verifyCallback} />
            </GoogleReCaptchaProvider>
        </ThemeProvider>
    </Provider>
}
