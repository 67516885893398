const breakpoints = ['320px', '480px', '576px', '768px', '992px', '1200px', '1400px']

breakpoints.xxxs = breakpoints[0]
breakpoints.xxs = breakpoints[1]
breakpoints.xs = breakpoints[2]
breakpoints.sm = breakpoints[3]
breakpoints.md = breakpoints[4]
breakpoints.lg = breakpoints[5]
breakpoints.xl = breakpoints[6]
breakpoints.xxl = breakpoints[6]

export default breakpoints
