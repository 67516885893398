const fontSizes = [
    '8px', // xxs
    '10px', // xs
    '12px', // sm
    '14px', // md, body
    '16px', // lg, h5
    '18px', // xl, h4
    '20px', // xxl, h3
    '24px', // xxxl
    '26px', // h2
    '28px',
    '30px',
    '32px',
    '34px',
    '36px',
    '38px',
    '40px',
    '42px',
    '44px', // h1
]

fontSizes.xxs = fontSizes[0]
fontSizes.xs = fontSizes[1]
fontSizes.sm = fontSizes[2]
fontSizes.md = fontSizes[3]
fontSizes.lg = fontSizes[4]
fontSizes.xl = fontSizes[5]
fontSizes.xxl = fontSizes[6]
fontSizes.xxxl = fontSizes[7]

fontSizes.body = fontSizes[3]
fontSizes.h1 = fontSizes[17]
fontSizes.h2 = fontSizes[8]
fontSizes.h3 = fontSizes[6]
fontSizes.h4 = fontSizes[5]
fontSizes.h5 = fontSizes[4]

export default fontSizes
