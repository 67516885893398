/* Numeric keyword values */
const fontWeights = [
    100,
    200,
    300,
    400, // Normal
    500,
    600, // Semibold
    700, // Bold
    800,
    900,
]

fontWeights.regular = fontWeights[3]
fontWeights.medium = fontWeights[4]
fontWeights.semibold = fontWeights[5]
fontWeights.bold = fontWeights[6]

export default fontWeights
