import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isRegistering: false,
    registerError: false,
    message: '',
    errors: '',
    permissionsTier: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        requestRegister: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            state.isRegistering = true
            state.registerError = false
        },
        registerSuccess: (state) => {
            state.isRegistering = false
            state.registered = true
            state.message = 'User has been registered'
        },
        registerError: (state) => {
            state.isRegistering = false
            state.registered = false
            state.registerError = true
            state.message = 'There was an error.'
        },
        resetUsers: (state) => initialState,
        setPermissionsTier: (state, action) => {
            state.permissionsTier = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    requestRegister,
    registerSuccess,
    registerError,
    resetUsers,
    setPermissionsTier,
} = userSlice.actions

export default userSlice.reducer
