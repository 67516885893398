const forms = {
    input: {
        borderWidth: 0,
    },
    darkMode: {
        input: {
            borderColor: 'white',
            borderWidth: '1px',
            fontFamily: 'body',
            fontWeight: 'regular',
            fontSize: 'md',
        },
        submit: {
            bg: 'transparent',
            borderLeftColor: 'white',
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            borderBottomColor: 'transparent',
            borderWidth: '1px',
            color: 'aquaDark',
            cursor: 'pointer',
            fontFamily: 'body',
            fontWeight: 'regular',
            fontSize: 'md',
            marginRight: 'xxs',
            marginTop: 'xxs',
            paddingTop: 'xxs',
            paddingBottom: 'xxs',
            paddingLeft: 0,
            paddingRight: 0,
            willChange: 'background-color, border-color, color',

            ':hover': {
                bg: 'aquaDark',
                borderColor: 'aquaDark',
                color: 'white',
                transitionDuration: '200ms, 100ms, 100ms',
                transitionTimingFunction: 'linear',
                transitionProperty: 'background-color, border-color, color',
            },

            ':focus': {
                borderStyle: 'dotted',
                borderColor: 'white',
                transitionProperty: 'none',
            },
        },
    },
    select: {
        borderRadius: 9999,
    },
    textarea: {},
    label: {},
    radio: {},
    checkbox: {},
};

export default forms
