import { createGlobalStyle } from 'styled-components'
import theme from './Theme'

export const GlobalStyle = createGlobalStyle`    
    html, body {
        font-family: ${theme.fonts.body};
        font-size: ${theme.fontSizes.body};
        font-style: normal;
        font-weight: ${theme.fontWeights.regular};
        height: 100%;
        letter-spacing: 0;
        margin: 0;
        padding: 0;
    }

    img, svg {
        border: 0;
        /* https://web.dev/content-visibility/ */
        /* content-visibility: auto; */
        display: block;
        height: auto;
        max-width: 100%;
        width: 100%;
    }

    .grecaptcha-badge {
        visibility: hidden;
    }

    .errorMessage {
        color: ${(props) => props.theme.colors.errorDark};
        display: block;
        margin-top: ${(props) => props.theme.space.xs};
    }
    
    input:not([type='hidden']),
    textarea {
        border-color: ${(props) => props.theme.colors.aquaMedium};
        border-radius: 0;
        border-style: solid;
        border-width: 1px;
        font-family: ${(props) => props.theme.fonts.body};
        margin-top: ${(props) => props.theme.space.md};
        outline: none;

        transition-duration: 300ms;
        transition-timing-function: ease-out;
        transition-property: border-color;

        will-change: border-color;

        :focus {
            border-color: ${(props) => props.theme.colors.aquaDark};
        }
        :first-child,
        :first-of-type {
            margin-top: 0;
        }
    }

    .legacy-feature-image {
        margin: 20px 0;
        .legacy-feature-caption {
            padding: 15px 10px;
            text-align: center;
            font-style: italic;
            opacity: 0.6;
        }
    }
    
    @media (min-width: 760px) {
        .legacy-feature-dual-image {
            display: flex;
            margin: 20px -10px;
            .legacy-feature-image {
                padding: 0 10px;
                margin: 0;
            }
        }
    }
`
