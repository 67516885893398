const text = {
    paragraph: {
        fontWeight: 'regular',
        fontSize: 'body',
        lineHeight: '20px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    h1: {
        fontWeight: 'semibold',
        letterSpacing: '2px',
        fontSize: 'h1',
    },
    // h1Small: {
    //     fontWeight: 'semibold',
    //     letterSpacing: '4px',
    //     fontSize: 'h2',
    // },
    h2: {
        fontFamily: 'heading',
        fontSize: 'h2',
        fontWeight: 'semibold',
        letterSpacing: '4px',
        marginBottom: '10px',
    },
    h3: {
        fontFamily: 'heading',
        fontWeight: 'semibold',
        letterSpacing: '2px',
        fontSize: 'h3',
    },
    h4: {
        fontFamily: 'heading',
        fontWeight: 'semibold',
        letterSpacing: '2px',
        fontSize: 'h4',
    },
    h5: {
        fontFamily: 'heading',
        fontWeight: 'semibold',
        letterSpacing: '3px',
        fontSize: 'h5',
    },
    body: {
        fontWeight: 'regular',
        fontSize: 'body',
    },
    bigText: {
        color: 'greyMedium',
        fontFamily: 'heading',
        fontWeight: 'semibold',
        fontSize: '220px',
        lineHeight: '205px',
        opacity: 0.3,
        '@media screen and (max-width: 768px)': {
            display: 'none',
        },
    },
    bigTextMobile: {
        color: 'greyMedium',
        display: 'inline-block',
        fontFamily: 'heading',
        fontWeight: 'semibold',
        fontSize: '75px',
        lineHeight: '205px',
        opacity: .3,
    },
    quote: {
        color: 'black',
        fontFamily: 'playfair',
        fontSize: 'xxxl',
        fontStyle: 'italic',
    },
}

export default text
