module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"vzt1qgv"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false,"cookieFlags":"secure;samesite=none"},"googleTagManager":{"trackingId":"","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer","cookieFlags":"secure;samesite=none"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel","controlCookieName":"gdpr-marketing-enabled"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kidston","short_name":"Kidston","start_url":"/","background_color":"#77b7be","theme_color":"#77b7be","display":"standalone","icon":"static/favicon-512x512.png","icons":[{"src":"static/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"static/favicon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"static/favicon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"static/favicon-152x152.png","sizes":"120x120","type":"image/png"},{"src":"static/favicon-167x167.png","sizes":"167x167","type":"image/png"},{"src":"static/favicon-180x180.png","sizes":"196x196","type":"image/png"},{"src":"static/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/favicon-196x196.png","sizes":"196x196","type":"image/png"},{"src":"static/favicon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/favicon-196x196.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"crossOrigin":"use-credentials","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156692550-1","head":false,"defer":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/about/*","/restorations"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
