const colors = {
    aquaDark: '#77b7be',
    aquaMedium: '#8ecfd6',
    aquaLight: '#e4f1f2',
    black: '#101010',
    charcoal: '#1b1b1b',
    disabled: '#979797',
    errorDark: '#e96338', // Typically used for text
    errorLight: '#be7b77', // Typically used for outlines
    greyDark: '#8a8b8a',
    greyLight: '#e0e0e0',
    greyMedium: '#d8d8d8',
    plum: '#64253f',
    red: '#E96338',
    transparent: 'transparent',
    white: '#fff',
}

export default colors
