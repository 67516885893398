import { configureStore } from '@reduxjs/toolkit'
import Globals from './reducers/globals'
import Preview from './reducers/preview'
import Auth from './reducers/auth'
import User from './reducers/user'
import Dialog from './reducers/dialog'
import Modal from './reducers/modal'
import Products from './reducers/products'

export const store = configureStore({
    reducer: {
        Globals,
        Auth,
        User,
        Dialog,
        Preview,
        Modal,
        Products,
    },
})
