import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: '',
}

export const previewSlice = createSlice({
    name: 'preview',
    initialState,
    reducers: {
        previewState: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            state.id = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    previewState,
} = previewSlice.actions

export default previewSlice.reducer
