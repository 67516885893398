import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    content: false,
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        modalToggle: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            const { isOpen, content } = JSON.parse(action.payload)

            state.isOpen = isOpen
            state.content = content
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    modalToggle,
} = modalSlice.actions

export default modalSlice.reducer
