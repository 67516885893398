import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    product: null,
}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        saveProduct: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            state.product = JSON.parse(action.payload)
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    saveProduct,
} = productsSlice.actions

export default productsSlice.reducer
