const buttons = {
    primary: {
        bg: 'transparent',
        borderColor: 'aquaDark',
        borderStyle: 'solid',
        borderRadius: 0,
        borderWidth: '2px',
        color: 'black',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'semibold',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '192px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color',
        ':hover, :active': {
            bg: 'aquaDark',
            color: 'white',
            borderColor: 'aquaDark',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color',
            transitionDuration: '200ms',
        }, ':focus': {
            bg: 'aquaDark',
            borderColor: 'white',
            borderStyle: 'dashed',
            color: 'white',
            outline: 'none',
        },
    },
    primary_alt: {
        bg: 'transparent',
        borderColor: 'black',
        borderStyle: 'solid',
        borderRadius: 0,
        borderWidth: '2px',
        color: 'black',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'semibold',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '192px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color, color',
        ':hover, :active': {
            bg: 'black',
            color: 'white',
            borderColor: 'black',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color, color',
            transitionDuration: '200ms',
        }, ':focus': {
            bg: 'black',
            borderColor: 'white',
            borderStyle: 'dashed',
            color: 'white',
            outline: 'none',
        },
    },
    primary_alt_alt: {
        bg: 'transparent',
        borderColor: 'black',
        borderStyle: 'solid',
        borderRadius: 0,
        borderWidth: '2px',
        color: 'aquaDark',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'semibold',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '192px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color',
        ':hover, :active': {
            bg: 'black',
            color: 'aquaDark',
            borderColor: 'black',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color',
            transitionDuration: '200ms',
        }, ':focus': {
            bg: 'black',
            borderColor: 'white',
            borderStyle: 'dashed',
            color: 'white',
            outline: 'none',
        },
    },
    primary_aquaDark: {
        bg: 'transparent',
        borderColor: 'aquaMedium',
        borderStyle: 'solid',
        borderRadius: 0,
        borderWidth: '2px',
        color: 'white',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'semibold',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '192px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color',
        ':hover, :active': {
            bg: 'aquaMedium',
            color: 'white',
            borderColor: 'aquaMedium',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color',
            transitionDuration: '200ms',
        }, ':focus': {
            bg: 'aquaMedium',
            borderColor: 'white',
            borderStyle: 'dashed',
            color: 'white',
            outline: 'none',
        },
    },
    primary_greyLight: {
        bg: 'greyLight',
        borderColor: 'aquaDark',
        borderStyle: 'solid',
        borderRadius: 0,
        borderWidth: '2px',
        color: 'black',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'semibold',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '192px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color',
        ':hover, :active': {
            bg: 'aquaDark',
            color: 'white',
            borderColor: 'aquaDark',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color, color',
            transitionDuration: '200ms',
        }, ':focus': {
            bg: 'aquaDark',
            borderColor: 'white',
            borderStyle: 'dashed',
            color: 'white',
            outline: 'none',
        },
    },
    primary_black: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderColor: 'aquaDark',
        borderStyle: 'solid',
        borderRadius: 0,
        borderWidth: '2px',
        color: 'white',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'semibold',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '192px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color',
        ':hover, :active': {
            bg: 'aquaDark',
            color: 'white',
            borderColor: 'aquaDark',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color',
            transitionDuration: '200ms',
        }, ':focus': {
            bg: 'aquaDark',
            borderColor: 'white',
            borderStyle: 'dashed',
            color: 'white',
            outline: 'none',
        },
    },
    primary_black_alt: {
        bg: 'transparent',
        borderColor: 'greyDark',
        borderStyle: 'solid',
        borderRadius: 0,
        borderWidth: '2px',
        color: 'white',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'semibold',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '192px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color',
        ':hover, :active': {
            bg: 'greyDark',
            color: 'white',
            borderColor: 'greyDark',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color',
            transitionDuration: '200ms',
        }, ':focus': {
            bg: 'greyDark',
            borderColor: 'white',
            borderStyle: 'dashed',
            color: 'white',
            outline: 'none',
        },
    },
    secondary_aquaDark: {
        bg: 'transparent',
        borderColor: 'black',
        borderStyle: 'solid',
        borderRadius: 0,
        borderWidth: '2px',
        color: 'black',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'semibold',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '192px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color, color',
        ':hover, :active': {
            bg: 'black',
            color: 'white',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color, color',
            transitionDuration: '200ms',
        }, ':focus': {
            bg: 'black',
            borderColor: 'white',
            borderStyle: 'dashed',
            color: 'white',
            outline: 'none',
        },
    },
    text: {
        bg: 'transparent',
        color: 'black',
        cursor: 'pointer',
        display: 'inline-block',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'semibold',
        textDecoration: 'none',
        padding: 0,
        position: 'relative',
        '::before': {
            content: '\x00a0',
            bg: 'darkAqua',
            display: 'inline-block',
            bottom: '-3px',
            height: '2px',
            opacity: 0,
            width: 0,
            zIndex: 1,
            transitionDuration: '.5s,50ms',
            willChange: 'width,opacity',
        },
        ':hover': {
            textDecoration: 'underline',
        },
    },
}

export default buttons
