import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    open: false,
    id: '',
    content: '',
    confirm: false,
    item: '',
}

export const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        dialogState: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            const { open, id, content, item } = JSON.parse(action.payload)
            state.open = open
            state.id = id
            state.content = content || ''
            state.item = item || ''
        },
        dialogConfirm: (state, action) => {
            state.confirm = action.payload
            if (!action.payload) {
                state.open = false
                state.content = ''
                state.item = ''
            }
        },
        dialogClose: (state) => {
            state.open = false
            state.content = ''
            state.item = ''
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    dialogState,
    dialogConfirm,
    dialogClose,
} = dialogSlice.actions

export default dialogSlice.reducer
